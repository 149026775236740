<template>
  <div class="box" id="box">
    <img src="../assets/logo.png" alt="" class="small" />
    <div class="yy" id="yy"></div>
    <div class="big" id="big">
      <img src="../assets/logo.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: String,
    },
  },

  data() {
    return {
      mask_hide: {
        hide: true,
      },
      styleObj: {
        top: 0,
        left: 0,
      },
    };
  },

  mounted() {
    //  window.addEventListener('mousemove', function () {
    const box = document.getElementById("box");
    const yy = document.getElementById("yy");
    const big = document.getElementById("big");
    // var box =this.$refs.box
    // var yy = this.$refs.yy
    // var big = this.$refs.big
    box.addEventListener("mouseover", function () {
      yy.style.display = "block";
      big.style.display = "block";
    });
    box.addEventListener("mouseout", function () {
      yy.style.display = "none";
      big.style.display = "none";
    });
    box.addEventListener("mousemove", function (e) {
      var x = e.pageX - box.offsetLeft;
      var y = e.pageY - box.offsetTop;
      var width = x - yy.offsetWidth / 2;
      var height = y - yy.offsetHeight / 2;
      if (width <= 0) {
        width = 0;
      } else if (width >= box.offsetWidth - yy.offsetWidth) {
        width = 100;
      }
      if (height <= 0) {
        height = 0;
      } else if (height >= box.offsetHeight - yy.offsetHeight) {
        height = box.offsetHeight - yy.offsetHeight;
      }
      yy.style.left = width + "px";
      yy.style.top = height + "px";
      var bigimg = big.getElementsByTagName("img")[0];
      // 大图片的移动距离=遮挡层移动距离*大图片最大移动距离/遮挡层最大移动距离
      var bigx =
        (width * (bigimg.offsetWidth - big.offsetWidth)) /
        (box.offsetWidth - yy.offsetWidth);
      var bigy =
        (height * (bigimg.offsetHeight - big.offsetHeight)) /
        (box.offsetHeight - yy.offsetHeight);
      bigimg.style.left = -bigx + "px";
      bigimg.style.top = -bigy + "px";
    });
    // })
  },

  //    methods:{

  //       move(event){
  //         let mask = this.$refs.mask;
  //         // let big = this.$refs.big;
  //         //计算遮罩的左值以及顶部的值
  //         let left = event.offsetX - mask.offsetWidth / 2
  //         let top = event.offsetY - mask.offsetHeight / 2
  //         //判断方位超出的情况
  //         if (left < 0) {
  //           left = 0
  //         } else if (left > mask.offsetWidth) {
  //           left = 200
  //         }
  //         if (top < 0) {
  //           top = 0
  //         } else if (top > mask.offsetHeight) {
  //           top = 200
  //         }
  //         //将遮罩以及big图赋值定位  （此外big图为普通图的两倍大小 且方位为负值）
  //         mask.style.left = left + 'px'
  //         mask.style.top = top + 'px'
  //         // big.style.left = -2 * left + 'px'
  //         // big.style.top = -2 * top + 'px'

  //       },
  //       display(bool){
  //         this.mask_hide.hide=!bool
  //       },
  //     }
};
</script>

//css样式

<style lang="scss" scoped>
.box {
  position: relative;
  width: 200px;
  height: 200px;
}

.yy {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  background-color: yellow;
  opacity: 0.5;
  border: 1px solid #333;
  display: none;
}

.big {
  position: absolute;
  left: 210px;
  top: 0px;
  width: 500px;
  height: 500px;
  overflow: hidden;
  display: none;
}

.big > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 180%;
  height: 180%;
}
</style>